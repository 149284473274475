import React, { createContext, useContext } from "react";

type SessionContexType = {
  getAccessToken: () => string | null;
  getRefreshToken: () => string | null;
  updateTokens: (
    accessToken: string | null,
    refreshToken: string | null
  ) => void;
};

const SessionContext = createContext<SessionContexType | null>(null);

export const useSession = () => {
  const context = useContext(SessionContext);

  if (!context)
    throw new Error("SessionContext must be placed within SessionProvider");

  return context;
};

const SessionProvider: React.FC = ({ children }) => {
  const getAccessToken = () => window.localStorage.getItem("accessToken");
  const getRefreshToken = () => window.localStorage.getItem("refreshToken");

  const updateTokens = (
    accessToken: string | null,
    refreshToken: string | null
  ) => {
    if (accessToken !== null) {
      window.localStorage.setItem("accessToken", accessToken);
    } else {
      window.localStorage.removeItem("accessToken");
    }

    if (refreshToken !== null) {
      window.localStorage.setItem("refreshToken", refreshToken);
    } else {
      window.localStorage.removeItem("refreshToken");
    }
  };

  return (
    <SessionContext.Provider
      value={{
        getAccessToken,
        getRefreshToken,
        updateTokens,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export type ClientCategoryFormDialogValues = {
  name: string;
  logo: File | null;
};

export type ClientCategoryFormDialogErrors = {
  [K in keyof ClientCategoryFormDialogValues]?: string;
};

export interface ClientCategoryFormDialogProps {
  open: boolean;
  isNew: boolean;
  processing: boolean;
  values: ClientCategoryFormDialogValues;
  errors: ClientCategoryFormDialogErrors;
  onChange: (values: Partial<ClientCategoryFormDialogValues>) => void;
  onSubmit: () => void;
  onClose: () => void;
}

const ClientPartnerFormDialog: React.FC<ClientCategoryFormDialogProps> = ({
  open,
  isNew,
  processing,
  values,
  errors,
  onChange,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {isNew
          ? "Добавление клиентского партнера"
          : "Изменение клиентской партнера"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {isNew
            ? "Введите название клиентской партнера."
            : "Введите новое название клиентской партнера."}
        </DialogContentText>
        <TextField
          margin="dense"
          label="Название"
          value={values.name}
          error={!!errors.name}
          helperText={errors.name}
          onChange={(e) => onChange({ name: e.currentTarget.value })}
          fullWidth
          autoFocus
        />
        {values.logo && <div>Загружен {values.logo?.name}</div>}
        <input
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              onChange({ logo: e.target.files[0] });
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={processing}>
            Отмена
          </Button>
          <Button onClick={onSubmit} color="primary" disabled={processing}>
            {isNew ? "Создать" : "Изменить"}
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default ClientPartnerFormDialog;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useNotify } from "../../../../application/notify-context";
import DELETE_CLIENT_PARTNER_MUTATION, {
  DeleteClientPartnerMutation,
  DeleteClientPartnerMutationVars,
} from "../../graphql/delete-client-partner";
import GET_CLIENT_PARTNERS from "../../graphql/get-client-partners";
import ClientPartnerDeleteDialog from "./client-partner-delete-dialog";

const ClientCategoryDeleteDialogConnected: React.FC<{
  partner: string;
  deleted: boolean;
  onClose: () => void;
}> = ({ partner, deleted, onClose }) => {
  const { showError, showSuccess } = useNotify();

  const [processing, setProcessing] = useState(false);

  const [deletePartner] = useMutation<
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVars
  >(DELETE_CLIENT_PARTNER_MUTATION, {
    refetchQueries: [GET_CLIENT_PARTNERS],
  });

  return (
    <ClientPartnerDeleteDialog
      processing={processing}
      deleted={deleted}
      onClose={onClose}
      onAgree={async () => {
        setProcessing(true);
        try {
          const { data, errors } = await deletePartner({
            variables: { id: partner, input: { deleted } },
          });

          if (errors) {
            showError(errors.map((item) => item.message).join(", "));
            return;
          }

          if (!data) {
            showError("Неизвестная ошибка");
            return;
          }

          if (data.deleteClientPartner.__typename === "Error") {
            showError(data.deleteClientPartner.error);
            return;
          }

          showSuccess(deleted ? "Партнер удален" : "Партнер восстановлен");
          onClose();
        } catch (e: unknown) {
          showError("Неизвестная ошибка");
        } finally {
          setProcessing(false);
        }
      }}
    />
  );
};

export default ClientCategoryDeleteDialogConnected;

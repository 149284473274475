import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

const GuestGuard: React.FC = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;

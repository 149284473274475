import React from "react";
import styled from "styled-components/macro";
import styles from "./service-application-page.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { FieldErrors, useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { z } from "zod";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  Divider as MuiDivider,
  MenuItem,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  Select,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNotify } from "../../../application/notify-context";
import {
  ServiceApplicationTypeEnum,
  useGetApplicationQuery,
  useGetServiceCrmTypesQuery,
  useUpdateServiceApplicationMutation,
} from "../../../generated/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatesPickerSingle } from "../../../components/dates-picker-single";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Pagination = styled(MuiPagination)(spacing);

const ServiceApplicationLong = z.object({
  userName: z.string({
    required_error: "",
  }),
  projectName: z.string({
    required_error: "",
  }),
  term: z
    .date({
      required_error: "",
    })
    .or(z.string())
    .optional(),
  description: z
    .string({
      required_error: "Обязательное поле",
    })
    .min(1),
  budgetFrom: z.string({
    required_error: "",
  }),
  budgetTo: z.string({
    required_error: "",
  }),
  companyName: z
    .string({
      required_error: "Обязательное поле",
    })
    .min(1),
  phone: z.string({
    required_error: "",
  }),
  email: z.string({
    required_error: "",
  }),
  telegram: z.string({
    required_error: "",
  }),
  typeId: z.number().optional(),
});

const ServiceApplicationShort = z.object({
  userName: z
    .string({
      required_error: "Обязательное поле",
    })
    .min(1),
  companyName: z
    .string({
      required_error: "Обязательное поле",
    })
    .min(1),
  phone: z
    .string({
      required_error: "Обязательное поле",
    })
    .min(1),
  email: z
    .string({
      required_error: "Обязательное поле",
    })
    .min(1),
});

type ServiceApplicationType = z.infer<typeof ServiceApplicationLong>;

const defaultValues: ServiceApplicationType = {
  userName: "",
  projectName: "",
  term: "",
  description: "",
  budgetFrom: "",
  budgetTo: "",
  companyName: "",
  phone: "",
  email: "",
  telegram: "",
  typeId: undefined,
};

function transformData(data: any): ServiceApplicationType {
  if (!data)
    return {
      userName: "",
      projectName: "",
      term: "",
      description: "",
      budgetFrom: "",
      budgetTo: "",
      companyName: "",
      phone: "",
      email: "",
      telegram: "",
      typeId: undefined,
    };

  return {
    userName: data.userName ?? "",
    projectName: data.projectName ?? "",
    term: data.term ?? "",
    description: data.description ?? "",
    budgetFrom: data.budgetFrom ?? "",
    budgetTo: data.budgetTo ?? "",
    companyName: data.companyName ?? "",
    phone: data.phone ?? "",
    email: data.email ?? "",
    telegram: data.telegram ?? "",
    typeId: data.typeId,
  };
}

function ServiceApplicationPage() {
  const { showError, showSuccess } = useNotify();
  const { data: wt } = useGetServiceCrmTypesQuery();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const workTypes = React.useMemo(() => {
    return wt?.getServiceCrmTypes?.__typename === "GetServiceCrmTypes"
      ? wt.getServiceCrmTypes.types
      : [];
  }, [wt]);

  const { data, loading: appLoading } = useGetApplicationQuery({
    variables: {
      input: {
        id: +params.id!,
      },
    },
    skip: !params.id,
  });

  const isShort =
    data?.getApplication?.__typename === "GetApplication"
      ? data.getApplication.data.type === ServiceApplicationTypeEnum.Short
      : false;

  const form = useForm<ServiceApplicationType>({
    defaultValues,
    // @ts-ignore
    resolver: zodResolver(
      isShort ? ServiceApplicationShort : ServiceApplicationLong
    ),
  });

  React.useLayoutEffect(() => {
    if (!data || data?.getApplication?.__typename !== "GetApplication") return;

    form.reset(transformData(data.getApplication.data));
  }, [data]);

  const errors = form.formState.errors;
  const state = form.watch();

  const handleState =
    (key: keyof ServiceApplicationType) => (value: string | number) => {
      form.setValue(key, value);
    };

  const handleSelect = (value: number) => {
    form.setValue("typeId", value);
  };

  const [updateApplication, { loading }] =
    useUpdateServiceApplicationMutation();

  const handleSubmit = async (data: ServiceApplicationType) => {
    if (!params.id) return;

    try {
      if (isShort) {
        await updateApplication({
          variables: {
            input: {
              id: params.id,
              data: {
                userName: data.userName,
                companyName: data.companyName,
                phone: data.phone,
                email: data.email,
              },
            },
          },
        });
      } else {
        await updateApplication({
          variables: {
            input: {
              id: params.id,
              data,
            },
          },
        });
      }

      showSuccess("Заявка успешно обновленна");
    } catch (e) {
      showError("Не удалось обновить заявку");
    }
  };

  //console.log("ERRORS: ", errors);

  return (
    <>
      <Helmet title="Заявки" />
      <Typography variant="h3" gutterBottom display="inline">
        Заявка на услугу
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Клиенты</Typography>
        <Typography>Заявка на услуги</Typography>
        <Typography>Детали</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <div className={styles.ServiceApplicationPage}>
        <form
          className={styles.ServiceApplicationPage__Form}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          {!appLoading && (
            <>
              {isShort ? (
                <ShortForm
                  handleState={handleState}
                  state={state}
                  errors={errors}
                />
              ) : (
                <LongForm
                  handleSelect={handleSelect}
                  handleState={handleState}
                  workTypes={workTypes as any}
                  state={state}
                  errors={errors}
                />
              )}

              <div className={styles.ServiceApplicationPage__Buttons}>
                <Button
                  variant="contained"
                  color="success"
                  title="Сохранить"
                  type="submit"
                  disabled={loading}
                >
                  Сохранить
                </Button>
                <Button
                  variant="contained"
                  title="Отмена"
                  color="warning"
                  onClick={() => navigate(-1)}
                  disabled={loading}
                >
                  Отмена
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
}

type FormPropsType = {
  handleState: (
    key: keyof ServiceApplicationType
  ) => (value: string | number) => void;
  state: ServiceApplicationType;
  errors: FieldErrors<ServiceApplicationType>;
};

function ShortForm({ handleState, state, errors }: FormPropsType) {
  return (
    <div className={styles.ShortForm}>
      <div className={styles.Title}>Короткая заявка</div>
      <div className={styles.ShortForm__Fields}>
        <Input
          onChange={handleState("userName")}
          value={state.userName ? state.userName?.split(" ")[0] : ""}
          label="Имя"
          error={errors?.userName?.message}
        />
        <Input
          onChange={handleState("companyName")}
          value={state.companyName}
          label="Название компании"
          error={errors?.companyName?.message}
        />
        <Input
          onChange={handleState("phone")}
          value={state.phone}
          label="Телефон"
          error={errors?.phone?.message}
        />
        <Input
          onChange={handleState("email")}
          value={state.email}
          label="Email"
          error={errors?.email?.message}
        />
      </div>
    </div>
  );
}

function LongForm({
  handleSelect,
  handleState,
  state,
  workTypes,
  errors,
}: FormPropsType & {
  handleSelect: (value: number) => void;
  workTypes: Array<{ id: number; name: string }>;
}) {
  const type = React.useMemo(() => {
    return `${state?.typeId}`;
  }, [state.typeId]);

  return (
    <div className={styles.LongForm}>
      <div className={styles.LongForm__Block}>
        <div className={styles.Title}>Расскажите о проекте</div>
        <div className={styles.LongForm__Fields}>
          <Input
            label="Название"
            value={state?.projectName}
            onChange={handleState("projectName")}
          />
          <div className={styles.LongForm__Row}>
            <FormControl>
              <InputLabel id="work-status-label">Вид работ</InputLabel>
              <Select
                fullWidth
                labelId="work-status-label"
                displayEmpty
                onChange={(e) => {
                  handleSelect(+e.target.value);
                }}
                label="Вид работ"
                value={type}
                id="work-status"
              >
                {workTypes.map(({ id, name }) => {
                  return (
                    <MenuItem key={id} value={`${id}`}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <DatesPickerSingle
              initType="single"
              placeholder="Срок реализации"
              onChange={(date: [Date, Date][]) => {
                date.length === 0
                  ? handleState("term")(undefined as any)
                  : handleState("term")(date[0][0] as any);
              }}
              values={
                state.term ? [[new Date(state.term), new Date(state.term)]] : []
              }
            />
            {/* <DatePicker
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => e.preventDefault()}
                  {...params}
                  error={undefined}
                />
              )}
              minDate={today.setDate(today.getDate())}
              onChange={(date) => {
                if (
                  !date ||
                  new Date(startOfDay(date)) < new Date(startOfToday())
                )
                  return;

                handleState("term")(date);
              }}
              label="Срок реализации"
              value={state.term ? new Date(state.term) : undefined}
            /> */}
          </div>
          <Input
            onChange={handleState("description")}
            label="Описание"
            type="textarea"
            value={state.description}
            error={errors?.description?.message}
          />
          <div className={styles.LongForm__Row}>
            <Input
              onChange={handleState("budgetFrom")}
              label="Бюджет от"
              value={state.budgetFrom}
            />
            <Input
              onChange={handleState("budgetTo")}
              label="Бюджет до"
              value={state.budgetTo}
            />
          </div>
        </div>
      </div>
      <div className={styles.LongForm__Block}>
        <div className={styles.Title}>Контакты</div>
        <div className={styles.LongForm__Fields}>
          <div className={styles.LongForm__Row}>
            <Input
              onChange={handleState("userName")}
              label="Имя"
              value={
                state?.userName ? state.userName.split(" ")[0] : state?.userName
              }
            />
            <Input
              onChange={handleState("companyName")}
              label="Название компании"
              value={state?.companyName}
              error={errors?.companyName?.message}
            />
          </div>
          <div className={styles.LongForm__Row}>
            <Input
              onChange={handleState("email")}
              label="Email"
              value={state?.email}
            />
            <Input
              onChange={handleState("phone")}
              label="Телефон"
              value={state?.phone}
            />
          </div>
          <div className={styles.LongForm__Row}>
            <Input
              onChange={handleState("telegram")}
              label="Telegram"
              value={state?.telegram}
            />
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

type PropsType = {
  label: string;
  value?: string | number | null;
  type?: "text" | "textarea";
  onChange: (value: string) => void;
  error?: string | undefined;
};

function Input({
  onChange,
  label,
  value = "",
  type = "text",
  error,
}: PropsType) {
  return type === "textarea" ? (
    <div>
      <TextField
        multiline
        fullWidth
        disabled={false}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        label={label}
        value={value}
        error={!!error}
      />
    </div>
  ) : (
    <TextField
      fullWidth
      disabled={false}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      label={label}
      value={value}
      error={!!error}
    />
  );
}

export default ServiceApplicationPage;

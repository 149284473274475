import { Event } from "@mui/icons-material";
import { SidebarItemsType } from "../../types/sidebar";

import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  AtSign,
  DollarSign,
  FileText,
  Frown,
  Star,
  Archive,
} from "react-feather";

const ClientsSection = [
  {
    href: "/clients",
    icon: Users,
    title: "Пользователи",
    children: [
      {
        href: "/clients/all-users",
        title: "Пользователи",
      },
      {
        href: "/clients/not-active",
        title: "Новые",
      },
      {
        href: "/clients/spots",
        title: "Организаторы",
      },
      {
        href: "/clients/brands",
        title: "Бренды",
      },
      {
        href: "/clients/agencies",
        title: "Агентства",
      },
    ],
  },
  {
    href: "/clients/partnerships",
    icon: Briefcase,
    title: "Партнёрства",
  },
  {
    href: "/clients/event-list",
    icon: Event,
    title: "Мероприятия",
  },
  {
    href: "/clients/applications",
    icon: BookOpen,
    title: "Заявки на партнерства",
  },
  {
    href: "/clients/events",
    icon: Star,
    title: "Заявки на мероприятия",
  },
  {
    href: "/clients/services?page=1",
    icon: Archive,
    title: "Заявки на услуги",
  },
  {
    href: "/clients/complaints",
    icon: Frown,
    title: "Жалобы",
  },
] as SidebarItemsType[];

const SystemSection = [
  {
    href: "/system/enums",
    icon: List,
    title: "Данные",
    children: [
      {
        href: "/system/city-enum",
        title: "Города",
      },
      {
        href: "/system/channels",
        title: "Каналы",
      },
      {
        href: "/system/client-category-enum",
        title: "Категории клиентов",
      },
      {
        href: "/system/client-partners",
        title: "Партнеры клиентов",
      },

      {
        href: "/system/theme-tag-enum",
        title: "Тематика и тэги поиска",
      },
      {
        href: "/system/event-type-enum",
        title: "Категории мероприятий",
      },
      {
        href: "/system/target-audience-enum",
        title: "Ценовой сегмент",
      },
      {
        href: "/system/people-amount-enum",
        title: "Количество посетителей",
      },
      {
        href: "/system/sponsor-category-enum",
        title: "Категории спонсоров",
      },
    ],
  },
  {
    href: "/system/email-templates",
    icon: AtSign,
    title: "Шаблоны email",
  },
  {
    href: "/system/doc-templates",
    icon: FileText,
    title: "Шаблоны документов",
  },
] as SidebarItemsType[];

const FinanceSection = [
  {
    href: "/finance/payments",
    icon: DollarSign,
    title: "Платежи",
  },
] as SidebarItemsType[];

const CrmSection = [
  {
    href: "/crm/operators",
    icon: Users,
    title: "Операторы",
  },
  {
    href: "/crm/assistants",
    icon: Users,
    title: "Сотрудники поддержки",
  },
] as SidebarItemsType[];

const ContentSection = [
  {
    href: "/content/freelance",
    icon: Briefcase,
    title: "Фриланс",
  },
] as SidebarItemsType[];

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
      {
        href: "/dashboard/analytics",
        title: "Analytics",
      },
      {
        href: "/dashboard/saas",
        title: "SaaS",
      },
    ],
  },
  {
    href: "/pages",
    icon: Layout,
    title: "Pages",
    children: [
      {
        href: "/pages/profile",
        title: "Profile",
      },
      {
        href: "/pages/settings",
        title: "Settings",
      },
      {
        href: "/pages/pricing",
        title: "Pricing",
      },
      {
        href: "/pages/chat",
        title: "Chat",
      },
      {
        href: "/pages/blank",
        title: "Blank Page",
      },
    ],
  },
  {
    href: "/projects",
    icon: Briefcase,
    title: "Projects",
    badge: "8",
  },
  {
    href: "/orders",
    icon: ShoppingCart,
    title: "Orders",
  },
  {
    href: "/invoices",
    icon: CreditCard,
    title: "Invoices",
    children: [
      {
        href: "/invoices",
        title: "List",
      },
      {
        href: "/invoices/detail",
        title: "Detail",
      },
    ],
  },
  {
    href: "/tasks",
    icon: CheckSquare,
    title: "Tasks",
    badge: "17",
  },
  {
    href: "/calendar",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/auth",
    icon: Users,
    title: "Auth",
    children: [
      {
        href: "/auth/sign-in",
        title: "Sign In",
      },
      {
        href: "/auth/sign-up",
        title: "Sign Up",
      },
      {
        href: "/auth/reset-password",
        title: "Reset Password",
      },
      {
        href: "/auth/404",
        title: "404 Page",
      },
      {
        href: "/auth/500",
        title: "500 Page",
      },
    ],
  },
] as SidebarItemsType[];

const elementsSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Components",
    children: [
      {
        href: "/components/alerts",
        title: "Alerts",
      },
      {
        href: "/components/accordion",
        title: "Accordion",
      },
      {
        href: "/components/avatars",
        title: "Avatars",
      },
      {
        href: "/components/badges",
        title: "Badges",
      },
      {
        href: "/components/buttons",
        title: "Buttons",
      },
      {
        href: "/components/cards",
        title: "Cards",
      },
      {
        href: "/components/chips",
        title: "Chips",
      },
      {
        href: "/components/dialogs",
        title: "Dialogs",
      },
      {
        href: "/components/lists",
        title: "Lists",
      },
      {
        href: "/components/menus",
        title: "Menus",
      },
      {
        href: "/components/pagination",
        title: "Pagination",
      },
      {
        href: "/components/progress",
        title: "Progress",
      },
      {
        href: "/components/snackbars",
        title: "Snackbars",
      },
      {
        href: "/components/tooltips",
        title: "Tooltips",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/pickers",
        title: "Pickers",
      },
      {
        href: "/forms/selection-controls",
        title: "Selection Controls",
      },
      {
        href: "/forms/selects",
        title: "Selects",
      },
      {
        href: "/forms/text-fields",
        title: "Text Fields",
      },
      {
        href: "/forms/editors",
        title: "Editors",
      },
      {
        href: "/forms/formik",
        title: "Formik",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
    children: [
      {
        href: "/tables/simple-table",
        title: "Simple Table",
      },
      {
        href: "/tables/advanced-table",
        title: "Advanced Table",
      },
      {
        href: "/tables/data-grid",
        title: "Data Grid",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    children: [
      {
        href: "/icons/material-icons",
        title: "Material Icons",
      },
      {
        href: "/icons/feather-icons",
        title: "Feather Icons",
      },
    ],
  },
  {
    href: "/maps",
    icon: Map,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
] as SidebarItemsType[];

const docsSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Documentation",
  },
  {
    href: "/changelog",
    icon: List,
    title: "Changelog",
    badge: "v3.2.1",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Система",
    pages: SystemSection,
  },
  {
    title: "Клиенты",
    pages: ClientsSection,
  },
  {
    title: "Финансы",
    pages: FinanceSection,
  },
  {
    title: "CRM",
    pages: CrmSection,
  },
  {
    title: "Контент",
    pages: ContentSection,
  },
  ...(process.env.NODE_ENV !== "production"
    ? [
        {
          title: "Pages",
          pages: pagesSection,
        },
        {
          title: "Elements",
          pages: elementsSection,
        },
        {
          title: "Material App",
          pages: docsSection,
        },
      ]
    : []),
];

export default navItems;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { graphqlConfig } from "../../config";
import { useSession } from "../session-context";
import initApolloClient from "./init-apollo-client";

const GraphqlProvider: React.FC = ({ children }) => {
  const client = initApolloClient({
    apiUrl: graphqlConfig.apiUrl,
    wsUrl: graphqlConfig.wsUrl,
    locale: "ru",
    actions: useSession(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface СlientPartnerDeleteDialogProps {
  processing: boolean;
  deleted: boolean;
  onClose: () => void;
  onAgree: () => void;
}

const ClientPartnerDeleteDialog: React.FC<СlientPartnerDeleteDialogProps> = ({
  processing,
  deleted,
  onClose,
  onAgree,
}) => (
  <Dialog
    open={true}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {deleted
        ? "Удалить клиентского партнера"
        : "Восстановить клиентского партнера"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {deleted
          ? "Вы действительно хотите удалить клиентского партнера?"
          : "Вы действительно хотите восстановить клиентского партнера?"}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" disabled={processing}>
        Отменить
      </Button>
      <Button onClick={onAgree} color="primary" disabled={processing} autoFocus>
        {deleted ? "Удалить" : "Восстановить"}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ClientPartnerDeleteDialog;

import React from "react";
import { spacing } from "@mui/system";
import { Button, TextField as MuiTextField } from "@mui/material";
import styled from "styled-components/macro";

export type SignInFormValues = {
  email: string;
  password: string;
};

export type SignInFormErrors = {
  email?: string;
  password?: string;
};

interface SignInProps {
  values: SignInFormValues;
  errors: SignInFormErrors;
  isProcessing: boolean;
  onChange: (value: Partial<SignInFormValues>) => void;
  onSubmit: () => void;
}

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const SignIn: React.FC<SignInProps> = ({
  values,
  errors,
  isProcessing,
  onChange,
  onSubmit,
}) => {
  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <TextField
        type="email"
        label="Email"
        value={values.email}
        error={!!errors.email}
        helperText={errors.email}
        onChange={(e) => onChange({ email: e.currentTarget.value })}
        my={2}
        fullWidth
      />
      <TextField
        type="password"
        label="Пароль"
        value={values.password}
        error={!!errors.password}
        helperText={errors.password}
        onChange={(e) => onChange({ password: e.currentTarget.value })}
        my={2}
        fullWidth
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isProcessing}
      >
        Войти
      </Button>
    </form>
  );
};

export default SignIn;

import { gql } from "@apollo/client";
import { GraphqlErrorType } from "../../../application/graphql/graphql-types";

export type ClientPartnerEnumModel = {
  id: string;
  name: string;
  logo: string;
  deleted: boolean;
};

export interface GetClientPartnerInfo {
  getClientPartnerInfo:
    | {
        __typename: "GetClientPartnerInfo";
        clientPartner: ClientPartnerEnumModel;
      }
    | GraphqlErrorType;
}

export interface GetClientPartnerInfoVars {
  id: string;
}

const GET_CLIENT_PARTNER_INFO = gql`
  query GetClientPartnerInfo($id: ID!) {
    getClientPartnerInfo(id: $id) {
      __typename
      ... on GetClientPartnerInfo {
        clientPartner {
          id
          name
          logo
          deleted
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_CLIENT_PARTNER_INFO;

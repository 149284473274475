import { gql } from "@apollo/client";
import { GraphqlErrorType } from "../../../application/graphql/graphql-types";

export interface DeleteClientPartnerMutation {
  deleteClientPartner:
    | {
        __typename: "DeleteClientPartner";
        partner: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface DeleteClientPartnerMutationVars {
  id: string | null;
  input: {
    deleted: boolean;
  };
}

const DELETE_CLIENT_PARTNER_MUTATION = gql`
  mutation DeleteClientPartner($id: ID, $input: DeleteClientPartnerInput!) {
    deleteClientPartner(id: $id, input: $input) {
      __typename
      ... on DeleteClientPartner {
        partner {
          id
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default DELETE_CLIENT_PARTNER_MUTATION;

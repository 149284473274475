import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

import { useNotify } from "../../../application/notify-context";
import Loader from "../../../components/Loader";
import GET_CLIENT_CATEGORY_ENUM_LIST, {
  GetClientCategoryEnumListQuery,
  GetClientCategoryEnumListQueryVars,
} from "../graphql/get-client-category-enum-list";
import ClientCategoryEnumList from "../components/city-client-category-list";
import ClientCategoryFormDialog from "../components/client-category-form-dialog";
import ClientCategoryDeleteDialog from "../components/client-category-delete-dialog";
import ClientPartnerList from "../components/client-partner-list";
import GET_CLIENT_PARTNERS, {
  GetClientPartnersQuery,
  GetClientPartnersVars,
} from "../graphql/get-client-partners";
import ClientPartnerFormDialog from "../components/client-partner-form-dialog";
import ClientPartnerDeleteDialog from "../components/client-partner-delete-dialog";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CityPartnersPage: React.FC = () => {
  const { showError } = useNotify();

  const [editCategoryId, setEditCategoryId] = useState<
    string | null | undefined
  >(undefined);
  const [deleteCAtegory, setDeleteCategory] = useState<{
    id: string;
    deleted: boolean;
  } | null>(null);

  const { data: partnersData, error: citiesError } = useQuery<
    GetClientPartnersQuery,
    GetClientPartnersVars
  >(GET_CLIENT_PARTNERS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (citiesError) {
      showError(citiesError.message);
    }
    if (partnersData?.getClientPartners.__typename === "Error") {
      showError(partnersData.getClientPartners.error);
    }
  }, [partnersData, citiesError]);

  if (partnersData?.getClientPartners.__typename !== "GetClientPartners") {
    return <Loader />;
  }

  return (
    <>
      <Helmet title="Партнеры городов" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Партнеры клиентов
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Система</Typography>
            <Typography>Партнеры клиентов</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditCategoryId(null)}
            >
              <AddIcon />
              Добавить
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ClientPartnerList
            partners={partnersData.getClientPartners.clientPartners}
            onEdit={setEditCategoryId}
            onDelete={(id) => setDeleteCategory({ id, deleted: true })}
            onRestore={(id) => setDeleteCategory({ id, deleted: false })}
          />
        </Grid>
      </Grid>

      {editCategoryId !== undefined && (
        <ClientPartnerFormDialog
          partner={editCategoryId}
          onClose={() => setEditCategoryId(undefined)}
        />
      )}

      {deleteCAtegory && (
        <ClientPartnerDeleteDialog
          partner={deleteCAtegory.id}
          deleted={deleteCAtegory.deleted}
          onClose={() => setDeleteCategory(null)}
        />
      )}
    </>
  );
};

export default CityPartnersPage;

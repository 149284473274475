import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Paper } from "@mui/material";
import { ReactComponent as Logo } from "../../../vendor/logo-black.svg";
import SignInComponent from "../components/signin";

const Brand = styled(Logo)`
  width: 126px;
  height: 24px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const SignIn: React.FC = () => {
  return (
    <>
      <Brand />
      <Wrapper>
        <Helmet title="Авторизация" />

        <SignInComponent />
      </Wrapper>
    </>
  );
};

export default SignIn;

import { gql } from "@apollo/client";
import { GraphqlErrorType } from "./graphql-types";

export interface RefreshTokenMutation {
  refreshToken:
    | { __typename: "RefreshToken"; accessToken: string; refreshToken: string }
    | GraphqlErrorType;
}

export interface RefreshTokenMutationVars {
  token: string;
}

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      __typename
      ... on RefreshToken {
        accessToken
        refreshToken
      }
      ... on Error {
        error
      }
    }
  }
`;

export default REFRESH_TOKEN_MUTATION;

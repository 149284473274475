import { gql } from "@apollo/client";
import { GraphqlErrorType } from "../../../application/graphql/graphql-types";

export interface UpdateClientPartnerMutation {
  updateClientPartner:
    | {
        __typename: "UpdateClientPartner";
        clientPartner: {
          id: string;
          name: string;
          logo: string;
          deleted?: boolean;
        };
      }
    | GraphqlErrorType;
}

export interface UpdateClientPartnerMutationVars {
  id: string | null;
  input: {
    name: string;
    logo: File;
  };
}

const UPDATE_CLIENT_PARTNER_MUTATION = gql`
  mutation UpdateClientPartner($id: ID, $input: UpdateClientPartnerInput!) {
    updateClientPartner(id: $id, input: $input) {
      __typename
      ... on UpdateClientPartner {
        clientPartner {
          id
          name
          logo
          deleted
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default UPDATE_CLIENT_PARTNER_MUTATION;

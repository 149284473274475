import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import jsonwebtoken from "jsonwebtoken";
import REFRESH_TOKEN_MUTATION, {
  RefreshTokenMutation,
  RefreshTokenMutationVars,
} from "./refresh-token";

const initApolloClient = (params: {
  apiUrl: string;
  wsUrl: string;
  locale: string | undefined;
  actions: {
    getAccessToken: () => string | null;
    getRefreshToken: () => string | null;
    updateTokens: (
      accessToken: string | null,
      refreshToken: string | null
    ) => void;
  };
}) => {
  const {
    apiUrl,
    wsUrl,
    locale,
    actions: { getAccessToken, getRefreshToken, updateTokens },
  } = params;

  const authLink = setContext(async (operation, { headers }) => {
    let at = getAccessToken();
    if (operation.operationName !== "RefreshToken" && at) {
      const atJson = jsonwebtoken.decode(at) as { exp: string } | null;
      const now = Date.now() / 1000;

      if (atJson && parseInt(atJson.exp) <= now) {
        const rt = getRefreshToken();

        if (rt) {
          const rtJson = jsonwebtoken.decode(rt) as { exp: string } | null;

          if (rtJson && parseInt(rtJson.exp) <= now) {
            at = null;
            updateTokens(null, null);
          } else {
            const { data } = await apolloClientClient.mutate<
              RefreshTokenMutation,
              RefreshTokenMutationVars
            >({ mutation: REFRESH_TOKEN_MUTATION, variables: { token: rt } });

            if (data?.refreshToken.__typename === "RefreshToken") {
              at = data.refreshToken.accessToken;
              updateTokens(
                data.refreshToken.accessToken,
                data.refreshToken.refreshToken
              );
            } else {
              at = null;
              updateTokens(null, null);
            }
          }
        } else {
          at = null;
          updateTokens(null, null);
        }
      }
    }

    return {
      headers: {
        ...headers,
        authorization: at ? `Bearer ${at}` : "",
      },
    };
  });

  // const wsLink = new WebSocketLink({
  //   uri: wsUrl,
  //   options: {
  //     reconnect: true,
  //     connectionParams: () => {
  //       const authorization = localStorage.get("at");
  //       return { authorization };
  //     },
  //   },
  // });

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === "OperationDefinition" &&
  //       definition.operation === "subscription"
  //     );
  //   },
  //   wsLink,
  //   ApolloLink.from([authLink, createUploadLink({ uri: apiUrl }), httpLink])
  // );

  const apolloClientClient = new ApolloClient({
    ssrMode: false,
    link: ApolloLink.from([
      authLink,
      createUploadLink({
        uri: apiUrl,
        headers: { "x-user-locale": locale },
      }),
    ]),
    cache: new InMemoryCache(),
  });

  return apolloClientClient;
};

export default initApolloClient;

import { gql } from "@apollo/client";

export type MyOperatorModel = {
  id: string;
  email: string;
  name: string;
  photo: string | null;
};

export interface GetMyOperatorMutation {
  getMyOperator: {
    operator: MyOperatorModel;
  };
}

export interface GetMyOperatorMutationVars {}

const GET_MY_OPERATOR = gql`
  query GetMyOperator {
    getMyOperator {
      operator {
        id
        email
        name
        photo
      }
    }
  }
`;

export default GET_MY_OPERATOR;

import { gql } from "@apollo/client";
import { GraphqlErrorType } from "../../../application/graphql/graphql-types";

export type ClientPartnersModel = {
  id: string;
  name: string;
  logo: string;
  deleted: boolean;
};

export interface GetClientPartnersQuery {
  getClientPartners:
    | {
        __typename: "GetClientPartners";
        clientPartners: ClientPartnersModel[];
      }
    | GraphqlErrorType;
}

export interface GetClientPartnersVars {}

const GET_CLIENT_PARTNERS = gql`
  query GetClientPartners {
    getClientPartners {
      __typename
      ... on GetClientPartners {
        clientPartners {
          id
          name
          logo
          deleted
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_CLIENT_PARTNERS;

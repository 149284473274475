import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { processApolloWarnings } from "../../../../application/graphql/utils";
import { useNotify } from "../../../../application/notify-context";
import GET_CLIENT_CATEGORY_ENUM_INFO, {
  GetClientCategoryEnumInfoQuery,
  GetClientCategoryEnumInfoQueryVars,
} from "../../graphql/get-client-category-enum-info";
import GET_CLIENT_PARTNER_INFO, {
  GetClientPartnerInfo,
} from "../../graphql/get-client-partner-info";
import GET_CLIENT_PARTNERS from "../../graphql/get-client-partners";
import UPDATE_CLIENT_PARTNER_MUTATION, {
  UpdateClientPartnerMutation,
  UpdateClientPartnerMutationVars,
} from "../../graphql/update-client-partner";
import ClientPartnerFormDialog, {
  ClientCategoryFormDialogValues,
  ClientCategoryFormDialogErrors,
} from "./client-partner-form-dialog";

const ClientCategoryFormDialogConnected: React.FC<{
  partner: string | null;
  onClose: () => void;
}> = ({ partner, onClose }) => {
  const { showError, showSuccess } = useNotify();

  const [processing, setProcessing] = useState(false);
  const [formValues, setFormValues] = useState<ClientCategoryFormDialogValues>({
    name: "",
    logo: null,
  });
  const [formErrors, setFormErrors] = useState<ClientCategoryFormDialogErrors>(
    {}
  );

  const [updateClientCategoryEnum] = useMutation<
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVars
  >(UPDATE_CLIENT_PARTNER_MUTATION, {
    refetchQueries: [GET_CLIENT_PARTNERS],
  });

  const [
    getClientCategoryInfo,
    { data: categoryData, error: categoryError, loading: categoryLoading },
  ] = useLazyQuery<GetClientPartnerInfo, GetClientCategoryEnumInfoQueryVars>(
    GET_CLIENT_PARTNER_INFO
  );

  useEffect(() => {
    if (categoryError) {
      showError(categoryError.message);
    }

    if (categoryData?.getClientPartnerInfo.__typename === "Error") {
      showError(categoryData.getClientPartnerInfo.error);
    }

    async function update() {
      if (
        categoryData?.getClientPartnerInfo.__typename === "GetClientPartnerInfo"
      ) {
        const uploadedLogo = await fetch(
          categoryData.getClientPartnerInfo.clientPartner.logo
        );
        const logoBlob = await uploadedLogo.blob();
        setFormValues({
          name: categoryData.getClientPartnerInfo.clientPartner.name,
          logo: new File([logoBlob], "logo.png", { type: "image/png" }),
        });
      }
    }
    update();
  }, [categoryData, categoryError]);

  const isNew = partner === null;

  useEffect(() => {
    if (partner) {
      getClientCategoryInfo({ variables: { id: partner } });
    }
  }, [partner]);

  return (
    <ClientPartnerFormDialog
      open={!categoryLoading}
      isNew={isNew}
      processing={processing}
      values={formValues}
      errors={formErrors}
      onChange={(values) => {
        setFormValues({ ...formValues, ...values });
        setFormErrors({
          ...formErrors,
          ...Object.keys(values).reduce(
            (prev, cur) => ({
              ...prev,
              [cur]: undefined,
            }),
            {}
          ),
        });
      }}
      onSubmit={async () => {
        try {
          setProcessing(true);

          if (!formValues.logo) {
            showError("Необходимо загрузить логотип");
            return;
          }

          const { data, errors } = await updateClientCategoryEnum({
            variables: {
              id: partner,
              input: {
                name: formValues.name,
                logo: formValues.logo,
              },
            },
          });

          if (errors) {
            showError(errors.map((item) => item.message).join(", "));
            return;
          }

          if (!data) {
            showError("Неизвестная ошибка");
            return;
          }

          if (data.updateClientPartner.__typename === "Error") {
            if (data.updateClientPartner.warnings) {
              setFormErrors(
                processApolloWarnings(data.updateClientPartner.warnings)
              );
              return;
            }

            showError(data.updateClientPartner.error);
            return;
          }

          showSuccess(isNew ? "Партнер добавлен" : "Данные партнера изменены");
          onClose();
        } catch (e: unknown) {
          showError("Неизвестная ошибка");
        } finally {
          setProcessing(false);
        }
      }}
      onClose={onClose}
    />
  );
};

export default ClientCategoryFormDialogConnected;

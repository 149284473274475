import { gql } from "@apollo/client";
import { GraphqlErrorType } from "../../../application/graphql/graphql-types";

export interface SigninOperatorMutation {
  signinOperator:
    | {
        __typename: "SigninOperator";
        accessToken: string;
        refreshToken: string;
      }
    | GraphqlErrorType;
}

export interface SigninOperatorMutationVars {
  email: string;
  password: string;
}

const SIGNIN_OPERATOR_MUTATION = gql`
  mutation SigninOperator($email: String!, $password: String!) {
    signinOperator(email: $email, password: $password) {
      __typename
      ... on SigninOperator {
        accessToken
        refreshToken
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default SIGNIN_OPERATOR_MUTATION;

import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

type NotifyContexType = {
  showSuccess: (text: string) => void;
  showError: (text: string) => void;
};

const NotifyContext = createContext<NotifyContexType | null>(null);

export const useNotify = () => {
  const context = useContext(NotifyContext);

  if (!context)
    throw new Error("NotifyContext must be placed within SessionProvider");

  return context;
};

type NotifyType = {
  type: "success" | "error";
  message: string;
};

const NotifyProvider: React.FC = ({ children }) => {
  const [notify, setNotify] = useState<NotifyType | null>(null);

  const showSuccess = (message: string) => {
    setNotify({ type: "success", message });
  };

  const showError = (message: string) => {
    setNotify({ type: "error", message });
  };

  return (
    <NotifyContext.Provider
      value={{
        showSuccess,
        showError,
      }}
    >
      {children}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={notify !== null}
        autoHideDuration={5000}
        onClose={() => setNotify(null)}
        key="snackbar_1"
      >
        <Alert
          onClose={() => setNotify(null)}
          severity={notify?.type || "success"}
          sx={{ width: "100%" }}
        >
          {notify?.message}
        </Alert>
      </Snackbar>
    </NotifyContext.Provider>
  );
};

export default NotifyProvider;

import React from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography as MuiTypography,
  Paper as MuiPaper,
  IconButton,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Restore as RestoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { green, red } from "@mui/material/colors";

import { ClientCategoryEnumItemModel } from "../graphql/get-client-category-enum-list";
import { ClientPartnersModel } from "../graphql/get-client-partners";

interface ClientCategoryEnumListProps {
  partners: ClientPartnersModel[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onRestore: (id: string) => void;
}

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ChipProps extends SpacingProps {
  blocked?: boolean;
}
const Chip = styled(MuiChip)<ChipProps>`
  ${spacing};

  background: ${(props) => !props.blocked && green[500]};
  background: ${(props) => props.blocked && red[500]};
`;

const ClientPartnerList: React.FC<ClientCategoryEnumListProps> = ({
  partners,
  onEdit,
  onDelete,
  onRestore,
}) => (
  <Grid container spacing={6}>
    <Grid item xs={12}>
      {partners.length === 0 && (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Нет данных
            </Typography>
          </CardContent>
        </Card>
      )}

      {partners.length > 0 && (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableBody>
                {partners.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                    >
                      <TableCell align="left" size="small">
                        #{row.id}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">
                        {row.deleted && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Удаленный"
                            blocked
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="восстановить"
                          size="large"
                          onClick={() => onEdit(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        {row.deleted ? (
                          <IconButton
                            aria-label="восстановить"
                            size="large"
                            onClick={() => onRestore(row.id)}
                          >
                            <RestoreIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="удалить"
                            size="large"
                            onClick={() => onDelete(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Grid>
  </Grid>
);

export default ClientPartnerList;

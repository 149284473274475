import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../../components/Loader";

import useAuth from "../../hooks/useAuth";

const AuthGuard: React.FC = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" />;
  }

  return <>{children}</>;
};

export default AuthGuard;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GET_MY_OPERATOR from "../../../../application/graphql/get-my-operator";
import { processApolloWarnings } from "../../../../application/graphql/utils";
import { useNotify } from "../../../../application/notify-context";
import { useSession } from "../../../../application/session-context";
import SIGNIN_OPERATOR_MUTATION, {
  SigninOperatorMutation,
  SigninOperatorMutationVars,
} from "../../graphql/signin-operator";
import SignIn, { SignInFormValues, SignInFormErrors } from "./signin";

const SignInConnected: React.FC = () => {
  const navigate = useNavigate();
  const { showError } = useNotify();
  const { updateTokens } = useSession();

  const [isProcessing, setProcessing] = useState(false);
  const [formValues, setFormValues] = useState<SignInFormValues>({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState<SignInFormErrors>({
    email: "",
    password: "",
  });

  const [signIn] = useMutation<
    SigninOperatorMutation,
    SigninOperatorMutationVars
  >(SIGNIN_OPERATOR_MUTATION);

  return (
    <SignIn
      values={formValues}
      errors={formErrors}
      isProcessing={isProcessing}
      onChange={(values) => {
        setFormValues({ ...formValues, ...values });
        setFormErrors({
          ...formErrors,
          ...Object.keys(values).reduce(
            (prev, cur) => ({
              ...prev,
              [cur]: undefined,
            }),
            {}
          ),
        });
      }}
      onSubmit={async () => {
        setProcessing(true);
        try {
          const { data, errors } = await signIn({
            variables: formValues,
            refetchQueries: [GET_MY_OPERATOR],
          });

          if (errors) {
            showError(errors.map((item) => item.message).join(", "));
            return;
          }

          if (!data) {
            showError("Неизвестная ошибка");
            return;
          }

          if (data.signinOperator.__typename === "Error") {
            if (data.signinOperator.warnings) {
              setFormErrors(
                processApolloWarnings(data.signinOperator.warnings)
              );
              return;
            }

            showError(data.signinOperator.error);
            return;
          }

          updateTokens(
            data.signinOperator.accessToken,
            data.signinOperator.refreshToken
          );

          setFormErrors({});
          navigate("/");
        } catch (e: unknown) {
          showError("Неизвестная ошибка");
        } finally {
          setProcessing(false);
        }
      }}
    />
  );
};

export default SignInConnected;

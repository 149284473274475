import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./application/guards/auth-guard";
import GuestGuard from "./application/guards/guest-guard";

import Page404 from "./pages/auth/Page404";

// Auth components
import SignIn from "./features/signin/pages/signin";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
//import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import CityPartnersPage from "./features/enum/pages/client-partners-enum-page";
import ServiceApplicationsPage from "./features/clients/pages/service-applications-page";
import ServiceApplicationPage from "./features/clients/pages/service-application-page";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

// Данные для списков
const CityEnumPage = async(
  () => import("./features/enum/pages/city-enum-page")
);
const ClientCategoryEnumPage = async(
  () => import("./features/enum/pages/client-category-enum-page")
);
const ThemeTagEnumPage = async(
  () => import("./features/enum/pages/theme-tag-enum-page")
);
const EventTypeEnumPage = async(
  () => import("./features/enum/pages/event-type-enum-page")
);
const TargetAudienceEnumPage = async(
  () => import("./features/enum/pages/target-audience-enum-page")
);
const PeopleAmountEnumPage = async(
  () => import("./features/enum/pages/people-amount-enum-page")
);
const ChannelsPage = async(() => import("./features/enum/pages/channels-page"));

// Email шаблоны
const EmailTemplates = async(
  () => import("./features/email-templates/pages/email-templates-page")
);
const EmailTemplateInfo = async(
  () => import("./features/email-templates/pages/email-template-info-page")
);

// Шаблоны документов
const DocTemplates = async(
  () => import("./features/doc-templates/pages/doc-templates-page")
);
const DocTemplateInfo = async(
  () => import("./features/doc-templates/pages/doc-template-info-page")
);

///// Клиенты

// Все пользователи
const AllUsers = async(() => import("./features/clients/pages/all-users-page"));

// - Новые
const NotActiveClients = async(
  () => import("./features/clients/pages/not-active-clients-page")
);
const NotActiveClient = async(
  () => import("./features/clients/pages/not-active-client-page")
);
// - Брэнды
const Brands = async(() => import("./features/clients/pages/brands-page"));
// - Организаторы
const Spots = async(() => import("./features/clients/pages/spots-page"));
// - Агенства
const Agencies = async(() => import("./features/clients/pages/agencies-page"));

// Заявки на партнерства
const Applications = async(
  () => import("./features/clients/pages/applications-page")
);

// Заявки на мероприятия
const Events = async(() => import("./features/clients/pages/events-page"));

// Мероприятия
const EventList = async(
  () => import("./features/clients/pages/event-list-page")
);

// Детали мероприятия
const EventDetailsPage = async(
  () => import("./features/clients/pages/event-details")
);

const ClientPage = async(() => import("./features/clients/pages/client-page"));

// Жалобы
const ComplaintsPage = async(
  () => import("./features/clients/pages/complaints-page")
);

// Партнёрства
const PartnershipsPage = async(
  () => import("./features/clients/pages/partnerships-page")
);

// Операторы
const Operators = async(
  () => import("./features/operators/pages/operators-page")
);

// Сотрудники поддержки
const Assistants = async(
  () => import("./features/assistants/pages/assistants-page")
);

// Платежи
const Payments = async(() => import("./features/payments/pages/payments-page"));

// Фриланс
const Freelance = async(
  () => import("./features/freelance/pages/freelance-page")
);

const routes = [
  {
    path: "/health-check",
    element: <div>OK</div>,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <div />,
      },
    ],
  },
  {
    path: "system",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "city-enum",
        element: <CityEnumPage />,
      },
      {
        path: "channels",
        element: <ChannelsPage />,
      },
      {
        path: "client-partners",
        element: <CityPartnersPage />,
      },
      {
        path: "client-category-enum",
        element: <ClientCategoryEnumPage />,
      },
      {
        path: "theme-tag-enum",
        element: <ThemeTagEnumPage />,
      },
      {
        path: "event-type-enum",
        element: <EventTypeEnumPage />,
      },
      {
        path: "target-audience-enum",
        element: <TargetAudienceEnumPage />,
      },
      {
        path: "people-amount-enum",
        element: <PeopleAmountEnumPage />,
      },
      {
        path: "email-templates",
        element: <EmailTemplates />,
      },
      {
        path: "email-templates/:id",
        element: <EmailTemplateInfo />,
      },
      {
        path: "doc-templates",
        element: <DocTemplates />,
      },
      {
        path: "doc-templates/:id",
        element: <DocTemplateInfo />,
      },
    ],
  },
  {
    path: "clients",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all-users",
        element: <AllUsers />,
      },
      {
        path: "not-active",
        element: <NotActiveClients />,
      },
      {
        path: "not-active/:id",
        element: <NotActiveClient />,
      },
      {
        path: "brands",
        element: <Brands />,
      },
      {
        path: "spots",
        element: <Spots />,
      },
      {
        path: "agencies",
        element: <Agencies />,
      },
      {
        path: "applications",
        element: <Applications />,
      },
      {
        path: "event-list",
        element: <EventList />,
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "events/:id",
        element: <EventDetailsPage />,
      },
      {
        path: ":id",
        element: <ClientPage />,
      },
      {
        path: "partnerships",
        element: <PartnershipsPage />,
      },
      {
        path: "services",
        element: <ServiceApplicationsPage />,
      },
      {
        path: "services/:id",
        element: <ServiceApplicationPage />,
      },
      {
        path: "complaints",
        element: <ComplaintsPage />,
      },
    ],
  },
  {
    path: "finance",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "payments",
        element: <Payments />,
      },
    ],
  },
  {
    path: "crm",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "operators",
        element: <Operators />,
      },
      {
        path: "assistants",
        element: <Assistants />,
      },
    ],
  },
  {
    path: "content",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "freelance",
        element: <Freelance />,
      },
    ],
  },

  ...(process.env.NODE_ENV !== "production"
    ? [
        {
          path: "dashboard",
          element: <DashboardLayout />,
          children: [
            {
              path: "default",
              element: <Default />,
            },
            {
              path: "analytics",
              element: <Analytics />,
            },
            {
              path: "saas",
              element: <SaaS />,
            },
          ],
        },
        {
          path: "pages",
          element: <DashboardLayout />,
          children: [
            {
              path: "profile",
              element: <Profile />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
            {
              path: "pricing",
              element: <Pricing />,
            },
            {
              path: "chat",
              element: <Chat />,
            },
            {
              path: "blank",
              element: <Blank />,
            },
          ],
        },
        {
          path: "projects",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <Projects />,
            },
          ],
        },
        {
          path: "invoices",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <InvoiceList />,
            },
            {
              path: "detail",
              element: <InvoiceDetails />,
            },
          ],
        },
        {
          path: "orders",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <Orders />,
            },
          ],
        },
        {
          path: "tasks",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <Tasks />,
            },
          ],
        },
        {
          path: "calendar",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <Calendar />,
            },
          ],
        },
        {
          path: "components",
          element: <DashboardLayout />,
          children: [
            {
              path: "accordion",
              element: <Accordion />,
            },
            {
              path: "alerts",
              element: <Alerts />,
            },
            {
              path: "avatars",
              element: <Avatars />,
            },
            {
              path: "badges",
              element: <Badges />,
            },
            {
              path: "buttons",
              element: <Buttons />,
            },
            {
              path: "cards",
              element: <Cards />,
            },
            {
              path: "chips",
              element: <Chips />,
            },
            {
              path: "dialogs",
              element: <Dialogs />,
            },
            {
              path: "lists",
              element: <Lists />,
            },
            {
              path: "menus",
              element: <Menus />,
            },
            {
              path: "pagination",
              element: <Pagination />,
            },
            {
              path: "progress",
              element: <Progress />,
            },
            {
              path: "snackbars",
              element: <Snackbars />,
            },
            {
              path: "tooltips",
              element: <Tooltips />,
            },
          ],
        },
        {
          path: "forms",
          element: <DashboardLayout />,
          children: [
            {
              path: "pickers",
              element: <Pickers />,
            },
            {
              path: "selection-controls",
              element: <SelectionCtrls />,
            },
            {
              path: "selects",
              element: <Selects />,
            },
            {
              path: "text-fields",
              element: <TextFields />,
            },
            {
              path: "editors",
              element: <Editors />,
            },
            {
              path: "formik",
              element: <Formik />,
            },
          ],
        },
        {
          path: "tables",
          element: <DashboardLayout />,
          children: [
            {
              path: "simple-table",
              element: <SimpleTable />,
            },
            {
              path: "advanced-table",
              element: <AdvancedTable />,
            },
            {
              path: "data-grid",
              element: <DataGrid />,
            },
          ],
        },
        {
          path: "icons",
          element: <DashboardLayout />,
          children: [
            {
              path: "material-icons",
              element: <MaterialIcons />,
            },
            {
              path: "feather-icons",
              element: <FeatherIcons />,
            },
          ],
        },
        {
          path: "charts",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <Chartjs />,
            },
          ],
        },
        {
          path: "maps",
          element: <DashboardLayout />,
          children: [
            {
              path: "google-maps",
              element: <GoogleMaps />,
            },
            {
              path: "vector-maps",
              element: <VectorMaps />,
            },
          ],
        },
        {
          path: "documentation",
          element: <DocLayout />,
          children: [
            {
              path: "welcome",
              element: <Welcome />,
            },
            {
              path: "getting-started",
              element: <GettingStarted />,
            },
            {
              path: "routing",
              element: <Routing />,
            },
            {
              path: "auth/auth0",
              element: <Auth0 />,
            },
            {
              path: "auth/cognito",
              element: <Cognito />,
            },
            {
              path: "auth/firebase",
              element: <Firebase />,
            },
            {
              path: "auth/jwt",
              element: <JWT />,
            },
            {
              path: "guards",
              element: <Guards />,
            },
            {
              path: "environment-variables",
              element: <EnvironmentVariables />,
            },
            {
              path: "deployment",
              element: <Deployment />,
            },
            {
              path: "theming",
              element: <Theming />,
            },
            {
              path: "api-calls",
              element: <APICalls />,
            },
            {
              path: "redux",
              element: <Redux />,
            },
            {
              path: "internationalization",
              element: <Internationalization />,
            },
            {
              path: "eslint-and-prettier",
              element: <ESLintAndPrettier />,
            },
            {
              path: "migrating-to-next-js",
              element: <MigratingToNextJS />,
            },
            // {
            //   path: "assistants",
            //   element: <Support />,
            // },
          ],
        },
        {
          path: "changelog",
          element: <DocLayout />,
          children: [
            {
              path: "",
              element: <Changelog />,
            },
          ],
        },
        {
          path: "private",
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: "",
              element: <ProtectedPage />,
            },
          ],
        },
        {
          path: "*",
          element: <AuthLayout />,
          children: [
            {
              path: "*",
              element: <Page404 />,
            },
          ],
        },
      ]
    : []),
];

export default routes;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Input, TextField } from "@mui/material";
import { DatesPickerCalendar } from "./dates-picker-calendar";
import css from "./dates-picker.module.scss";
import { useWindowSize } from "react-use";
import clsx from "clsx";

interface DatesPickerProps {
  values: [Date, Date][];
  error?: boolean;
  index?: number;
  description?: string;
  placeholder?: string;
  onChange: (values: [Date, Date][]) => void;
  initType?: "single";
}

const MONTHS = [
  "ЯНВАРЬ",
  "ФЕВРАЛЬ",
  "МАРТ",
  "АПРЕЛЬ",
  "МАЙ",
  "ИЮНЬ",
  "ИЮЛЬ",
  "АВГУСТ",
  "СЕНТЯБРЬ",
  "ОКТЯБРЬ",
  "НОЯБРЬ",
  "ДЕКАБРЬ",
];

const LABEL_MONTHS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const getDate2 = (date1: Date) => {
  const date2 = new Date();
  date2.setMonth(date1.getMonth() + 1);

  return date2;
};

const getLabel = (date: Date) =>
  `${date.getDate()}.${LABEL_MONTHS[date.getMonth()]}.${date.getFullYear()}`;

const getDateLabel = (dateValues: Date[][], index?: number) => {
  if (dateValues.length === 0) {
    return "";
  }

  const values = index !== undefined ? [dateValues[index]] : dateValues;

  values.sort((a, b) => a[0].getTime() - b[0].getTime());

  if (values[0][0].getTime() === values[values.length - 1][1].getTime()) {
    return getLabel(values[0][0]);
  }

  return `${getLabel(values[0][0])} - ${getLabel(
    values[values.length - 1][1]
  )}`;
};

export const DatesPicker = ({
  values,
  error,
  description,
  placeholder,
  index,
  initType,
  onChange,
}: DatesPickerProps) => {
  const { width } = useWindowSize();

  const ref = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<"top" | "bottom">("bottom");
  const [active, setActive] = useState(false);
  const [type, setType] = useState<"days" | "range" | "single">(
    values.length > 1
      ? "days"
      : values.length === 0 || values[0][0].getTime() === values[0][1].getTime()
      ? "days"
      : "range"
  );

  React.useEffect(() => {
    setType(() =>
      values.length > 1
        ? "days"
        : values.length === 0 ||
          values[0][0].getTime() === values[0][1].getTime()
        ? "days"
        : "range"
    );
  }, [active]);

  React.useEffect(() => {
    if (!initType) return;
    setType(() => initType);
  }, [initType, active]);

  const [dates, setDates] = useState(values);

  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(getDate2(date1));

  React.useLayoutEffect(() => {
    if (!active) {
      return;
    }

    startDate.current = undefined;
    endDate.current = undefined;
    setDates(values);

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    window.addEventListener("click", clickHandler as any, true);

    const bound = pickerRef.current?.getBoundingClientRect();
    const rootBound = ref.current?.getBoundingClientRect();

    if (bound && rootBound) {
      //const windowHeight = window.innerHeight;

      //console.log("root", rootBound, "picker", bound, windowHeight)

      // if (windowHeight <= bound.height) {
      //   setPosition("bottom");
      // } else if (windowHeight - rootBound.y <= bound.height) {
      //   setPosition("top");
      // } else if (windowHeight - rootBound.y < bound.bottom + bound.height) {
      //   setPosition("top");
      // } else {
      //   setPosition("bottom");
      // }

      if (rootBound.top > bound.height + 70) {
        setPosition("top");
      } else {
        setPosition("bottom");
      }
    }

    return () => {
      window.removeEventListener("click", clickHandler as any);
    };
  }, [active]);

  const changeMonth1 = (value: number) => {
    if (width > 767) {
      if (
        value > 0 &&
        date1.getMonth() + 1 === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      ) {
        return;
      }
    }

    const _date1 =
      date1.getFullYear().toString() + (date1.getMonth() + 1).toString();
    const _date2 = date2.getFullYear().toString() + date2.getMonth().toString();

    // if (value > 0 && _date1 === _date2) {
    //   return;
    // }

    if (
      width > 767 &&
      value > 0 &&
      +_date1.slice(4) === 12 &&
      +_date2.slice(4) === 0
    ) {
      return;
    }

    const newDate = new Date(date1.getTime());
    newDate.setMonth(newDate.getMonth() + value);
    setDate1(newDate);
  };
  const changeMonth2 = (value: number) => {
    if (
      value < 0 &&
      date1.getMonth() + 1 === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    ) {
      return;
    }

    const _date1 =
      date1.getFullYear().toString() + (date1.getMonth() + 1).toString();
    const _date2 = date2.getFullYear().toString() + date2.getMonth().toString();

    //     if (value < 0 && _date1 === _date2) {
    //       return;
    //     }
    if (
      width > 767 &&
      value < 0 &&
      +_date1.slice(4) === 12 &&
      +_date2.slice(4) === 0
    ) {
      return;
    }

    const newDate = new Date(date2.getTime());
    newDate.setMonth(newDate.getMonth() + value);
    setDate2(newDate);
  };

  const startDate = useRef<Date>();
  const endDate = useRef<Date>();
  const selectDateHandler = (date: Date) => {
    if (type === "days" || type === "single") {
      const index = dates.findIndex(
        (item) => item[0].getTime() === date.getTime()
      );

      if (index === -1) {
        if (
          (dates.length < 5 && type === "days") ||
          (dates.length <= 1 && type === "single")
        ) {
          if (type === "days") {
            setDates(
              [...dates, [date, date] as [Date, Date]].sort(
                (a, b) => a[0].getTime() - b[0].getTime()
              )
            );
          } else {
            setDates(
              [[date, date] as [Date, Date]].sort(
                (a, b) => a[0].getTime() - b[0].getTime()
              )
            );
          }
        }
      } else {
        const newValues = [...dates];
        newValues.splice(index, 1);

        setDates(newValues);
      }

      return;
    }

    if (!startDate.current) {
      startDate.current = date;
      setDates([[date, date]]);
      return;
    }

    if (date < startDate.current) {
      endDate.current = new Date(startDate.current.getTime());
      startDate.current = date;
    } else {
      endDate.current = date;
    }

    if (startDate.current && endDate.current) {
      setDates([[startDate.current, endDate.current]]);

      endDate.current = undefined;
      startDate.current = undefined;
    }
  };

  return (
    <div className={css.Root} ref={ref}>
      <div className={css.TextField}>
        <TextField
          className={css.TextField__Input}
          value={getDateLabel(values, index)}
          error={error}
          fullWidth
          label={placeholder}
          disabled={false}
          onChange={() => {}}
          placeholder={placeholder}
        />
        <div
          className={css.TextField__Preview}
          onClick={(e: any) => {
            setActive(true);
          }}
        />
        <CalendarIcon
          className={css.TextField__Icon}
          onClick={() => {
            setActive(true);
          }}
        />
      </div>
      {active && (
        <div
          className={position === "bottom" ? css.Calendar : css.CalendarTop}
          ref={pickerRef}
        >
          <div className={css.Row}>
            <div
              className={clsx(
                css.Menu,
                initType === "single" && css.Menu_disable
              )}
            >
              {type !== "single" && (
                <>
                  <div
                    className={type === "days" ? css.ItemActive : css.Item}
                    onClick={() => {
                      setType("days");
                      setDates([]);
                    }}
                  >
                    Отметить дни
                  </div>
                  <div
                    className={type === "range" ? css.ItemActive : css.Item}
                    onClick={() => {
                      startDate.current = undefined;
                      endDate.current = undefined;
                      setType("range");
                      setDates([]);
                    }}
                  >
                    Указать период
                  </div>
                </>
              )}
            </div>
            <div className={css.MonthCaledar}>
              <div className={css.MonthActions}>
                <div
                  className={css.LeftButton}
                  onClick={() => changeMonth1(-1)}
                />
                {MONTHS[date1.getMonth()]} {date1.getFullYear()}
                <div
                  className={css.RightButton}
                  onClick={() => changeMonth1(1)}
                />
              </div>
              <DatesPickerCalendar
                type={type}
                month={date1.getMonth()}
                year={date1.getFullYear()}
                dates={dates}
                onSelect={selectDateHandler}
              />
            </div>
            <div className={css.MonthCaledar}>
              <div className={css.MonthActions}>
                <div
                  className={css.LeftButton}
                  onClick={() => changeMonth2(-1)}
                />
                {MONTHS[date2.getMonth()]} {date2.getFullYear()}
                <div
                  className={css.RightButton}
                  onClick={() => changeMonth2(1)}
                />
              </div>
              <DatesPickerCalendar
                type={type}
                month={date2.getMonth()}
                year={date2.getFullYear()}
                dates={dates}
                onSelect={selectDateHandler}
              />
            </div>
          </div>
          <div className={css.Buttons}>
            <Button
              size="small"
              style={{ color: "#6c7278" }}
              onClick={() => {
                onChange([]);
                setActive(false);
              }}
            >
              СБРОСИТЬ
            </Button>
            <Button
              size="small"
              style={{ color: "#00AFFA" }}
              onClick={() => {
                onChange(dates);
                setActive(false);
              }}
            >
              ПРИМЕНИТЬ
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

function CalendarIcon({
  className,
  onClick,
}: {
  className: string;
  onClick?: () => void;
}) {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" fill="white" />
      <g clip-path="url(#clip0_10900_7094)">
        <path
          d="M13.334 3.99935H16.0007C16.1775 3.99935 16.347 4.06959 16.4721 4.19461C16.5971 4.31964 16.6673 4.4892 16.6673 4.66602V15.3327C16.6673 15.5095 16.5971 15.6791 16.4721 15.8041C16.347 15.9291 16.1775 15.9993 16.0007 15.9993H4.00065C3.82384 15.9993 3.65427 15.9291 3.52925 15.8041C3.40422 15.6791 3.33398 15.5095 3.33398 15.3327V4.66602C3.33398 4.4892 3.40422 4.31964 3.52925 4.19461C3.65427 4.06959 3.82384 3.99935 4.00065 3.99935H6.66732V2.66602H8.00065V3.99935H12.0007V2.66602H13.334V3.99935ZM15.334 9.33268H4.66732V14.666H15.334V9.33268ZM12.0007 5.33268H8.00065V6.66602H6.66732V5.33268H4.66732V7.99935H15.334V5.33268H13.334V6.66602H12.0007V5.33268ZM6.00065 10.666H7.33398V11.9993H6.00065V10.666ZM9.33398 10.666H10.6673V11.9993H9.33398V10.666ZM12.6673 10.666H14.0007V11.9993H12.6673V10.666Z"
          fill="#B6BBBF"
        />
      </g>
      <defs>
        <clipPath id="clip0_10900_7094">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DatesPicker;

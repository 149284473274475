import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Eye } from "react-feather";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Input,
  Link as MuiLink,
  MenuItem,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  useUpdateServiceApplicationStatusMutation,
  useGetServiceApplicationsQuery,
  ServiceApplicationStatusEnum,
  ServiceApplicationTypeEnum,
} from "../../../generated/types";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Pagination = styled(MuiPagination)(spacing);

const INIT_COMPANY_NAME = "";
const INIT_PAGE_SIZE = 5;
const INIT_PAGE = 1;

function getSearchParams(search: string, page: number) {
  const searchParams = new URLSearchParams(search);
  searchParams.set("page", page.toString());
  return searchParams.toString();
}

function useNav(locationSearch: string) {
  const navigation = useNavigate();

  const setSearchParamsToQuery = (page: number) => {
    const searchParams = getSearchParams(locationSearch, page);
    navigation({
      search: searchParams.toString(),
    });
  };

  return {
    navigation,
    setSearchParamsToQuery,
  };
}

function ServiceApplicationsPage() {
  const { search: locationSearch } = useLocation();
  const { setSearchParamsToQuery } = useNav(locationSearch);

  const params = new URLSearchParams(locationSearch);
  const page = params.get("page") ?? INIT_PAGE;

  const [companyName, setCompanyName] = React.useState(INIT_COMPANY_NAME);
  const [pageSize] = React.useState(INIT_PAGE_SIZE);
  const [search, setSearch] = React.useState<string>("");

  const { data } = useGetServiceApplicationsQuery({
    variables: {
      input: {
        pageSize,
        page: +page,
        companyName,
      },
    },
    fetchPolicy: "no-cache",
  });

  const handlePage = (_e: React.ChangeEvent<unknown>, page: number) => {
    setSearchParamsToQuery(page);
  };

  const handleClearFilter = () => {
    setCompanyName(INIT_COMPANY_NAME);
    setSearch(INIT_COMPANY_NAME);
  };

  const handleConfirmFilter = () => {
    setCompanyName(search);
    setSearchParamsToQuery(1);
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
  };

  const applications = React.useMemo(() => {
    return data?.getApplications?.__typename === "GetApplications"
      ? data?.getApplications.data
      : [];
  }, [data]);

  const pagination = React.useMemo(() => {
    if (data?.getApplications?.__typename !== "GetApplications") {
      return {
        totalPages: 1,
        totalCount: 0,
      };
    }

    return {
      totalPages: data.getApplications.totalPages,
      totalCount: data.getApplications.totalCount,
    };
  }, [data]);

  // Application status
  const [changeStatus] = useUpdateServiceApplicationStatusMutation({
    refetchQueries: ["GetServiceApplications"],
  });

  const handleChangeStatus = async (
    id: string,
    status: ServiceApplicationStatusEnum
  ) => {
    try {
      await changeStatus({
        variables: {
          input: {
            id,
            status,
          },
        },
      });
    } catch (e) {
      console.error("CHANGE_STATUS_ERROR: ", e);
    }
  };

  return (
    <>
      {/* HEADER */}
      <Helmet title="Заявки" />
      <Typography variant="h3" gutterBottom display="inline">
        Заявки на услуги
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Клиенты</Typography>
        <Typography>Заявки на услуги</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {/* SEARCH */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        gap={{ xs: 6, sm: 6, md: 16 }}
      >
        <Input
          onChange={handleSearch}
          placeholder="Поиск по Бренд"
          value={search}
          fullWidth
        />
        <Stack direction={"row"} gap={2}>
          <Button onClick={handleClearFilter} variant="outlined">
            Очистить
          </Button>
          <Button onClick={handleConfirmFilter} variant="contained">
            Поиск
          </Button>
        </Stack>
      </Stack>

      {/* TABLE */}
      <Grid item xs={12} mt={12}>
        {applications.length === 0 && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Нет данных
              </Typography>
            </CardContent>
          </Card>
        )}

        {applications.length > 0 && (
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="right">№</TableCell>
                    <TableCell align="left">Название компании</TableCell>
                    <TableCell align="left">Дата</TableCell>
                    <TableCell align="left">Статус</TableCell>
                    <TableCell align="left">Тип</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {applications.map((row, index) => {
                    const address = row?.address;

                    const Links = ({
                      crm,
                      site,
                      showSiteBtn,
                    }: {
                      crm: string;
                      site: string;
                      showSiteBtn: boolean;
                    }) => {
                      return (
                        <Stack direction={"row"} gap={2}>
                          <MuiLink href={crm}>
                            <Button variant="contained" size="small">
                              CRM
                            </Button>
                          </MuiLink>
                          {showSiteBtn && (
                            <MuiLink href={site} target="_blank">
                              <Button variant="contained" size="small">
                                Сайт
                              </Button>
                            </MuiLink>
                          )}
                        </Stack>
                      );
                    };

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell align="right">#{row?.id}</TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            minWidth: "170px",
                            maxWidth: "400px",
                          }}
                        >
                          {row?.companyName}
                          {address && (
                            <Links
                              showSiteBtn={true}
                              site={
                                process.env.REACT_APP_WEBSITE_URL +
                                `/${address}`
                              }
                              crm={`/clients/services/${row.id}`}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <div style={{ marginBottom: "1.5px" }}>
                            {new Date(row?.createdAt).toLocaleDateString(
                              "ru-RU"
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <Select
                            sx={{
                              height: "44px",
                              width: "120px",
                            }}
                            onChange={(event) => {
                              if (!event.target.value || !row?.id) return;

                              handleChangeStatus(
                                row.id,
                                event.target
                                  .value as ServiceApplicationStatusEnum
                              );
                            }}
                            value={row?.status}
                          >
                            <MenuItem value={ServiceApplicationStatusEnum.New}>
                              Новая
                            </MenuItem>
                            <MenuItem
                              value={ServiceApplicationStatusEnum.InWork}
                            >
                              В работе
                            </MenuItem>
                            <MenuItem
                              value={ServiceApplicationStatusEnum.Agreed}
                            >
                              Согласована
                            </MenuItem>
                            <MenuItem
                              value={ServiceApplicationStatusEnum.NotAgreed}
                            >
                              Не договорились
                            </MenuItem>
                            <MenuItem
                              value={ServiceApplicationStatusEnum.Misdialed}
                            >
                              Недозвон
                            </MenuItem>
                            <MenuItem
                              value={ServiceApplicationStatusEnum.Duplicate}
                            >
                              Дубликат
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          {row?.type === ServiceApplicationTypeEnum.Short
                            ? "Краткая"
                            : "Полная"}
                        </TableCell>
                        <TableCell>
                          <Link to={`/clients/services/${row?.id}`}>
                            <Eye />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Grid>
      {pagination.totalCount > INIT_PAGE_SIZE && (
        <Pagination
          count={pagination.totalPages}
          page={Number(page)}
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          onChange={handlePage}
        />
      )}
    </>
  );
}

export default ServiceApplicationsPage;
